.Navbar {
    height: 72px;
    background-color: #000000;
    border-top: solid 1px #bbbbbb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}

.navMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
    max-width: 600px;
}

.navLink {
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.15s ease-in-out;
}
.navLink:hover {
    color: #cb3527;
}

@media (max-width: 499px) {
    .screen-s {
        display: none;
    }
}

@media (max-width:699px) {
    .screen-m {
        display: none;
    }
}