/* HERO */
.homeHero {
    background-image: url('/images/masseffect-pinball-cabinet-34.png');
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #ffffff;
    flex-grow: 1;
    color: #222222;
    min-height: calc(100vh - 72px); /* navbarHeight */
    box-sizing: border-box;
    text-align: right;
    /* The background is height-based, 30vh clears when the bg doesn't scale */
    padding: 24px 32px 24px 30vh;
}

.homeHeroTextContainer {
    padding-top: 10vh;
    text-shadow: 1px 1px 1px white, -1px -1px 1px rgba(255,255,255,0.5);
    font-family: "me123";
}


.homeHeroTitle {
    font-size: 32pt;
}

@media (max-width: 800px) {
    .homeHeroTextContainer {
        padding-top: 2vh;
    }
}

@media (max-width: 600px) {
    .homeHero {
        padding-left: 0;
        background-size: 80vw auto;
    }
    .homeHeroTextContainer {
        padding-top: 0;
    }
    .homeHeroSubtitle {
        padding-left: 32vw;
    }
}

@media (max-width: 440px) {
    .homeHero {
        padding-left: 24px;
    }
    .homeHeroTitle {
        font-size: 28pt;
    }
}


.homeHeroSubtitle {
    font-size: 24pt;
    font-style: italic;
}

.quote {
    color: #aaaaaa;
    font-style: italic;
}

/* INTRO */
.homeIntro {
    background-image: url('/images/masseffect-pinball-lowerplayfield-closeup-horiz.png');
    background-size: cover;
    background-position: center center;
    min-height: calc(90vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 24px 12px;
    box-sizing: border-box;
}

@media (min-width: 1200px) {
    .homeIntro {
        background-image: url('/images/masseffect-pinball-lowerplayfield-closeup-high.png');
    }
}

@media (max-width: 600px) {
    .homeIntro {
        background-image: url('/images/masseffect-pinball-lowerplayfield-closeup-vert.png');
    }
}

.homeIntroImage {
    max-width: 90vw;
}

.homeIntroP {
    width: 90vw;
    max-width: 600px;
    font-size: 14pt;
    text-align: justify;
    padding: 18px 12px;
}

/* GALLERY */
.homeGallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
}

.homeGalleryItem {
    flex-basis: 33%;
    height: 33vw;
    border: solid 1px white;
    box-sizing: border-box;
    cursor: pointer;
    flex-grow: 1;
    overflow: hidden;
}

@media (max-width: 600px) {
    .homeGalleryItem {
        flex-basis: 50%;
        height: 50vw;
    }
}

.homeGalleryItemImage {
    width: 100%;
    transition: transform 0.2s ease-in-out;
}

.homeGalleryItemImage:hover {
    transform: scale(1.1);
}

/* VIDEO */
.homeVideo {
    display: flex;
    flex-direction: row;
    background-color: #e68e2d;
}

.homeVideoContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 24px;
    color: black;
}

.homeVideoIframeContainer {
    height: 0;
    padding-top: 50%;
    position: relative;
    margin-bottom: 24px;
}

.homeVideoIframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 2px black;
    top: 0; left: 0; right: 0; bottom: 0;
}

.homeVideoTitle {
    font-size: 28px;
    font-family: "me123";
    padding-bottom: 24px;
}

.homeVideoSection {
    font-size: 14pt;
    padding-left: 12px;
    padding-bottom: 24px;
}

.homeVideoPlayer {
    max-width: 50vw;
    border-left: solid 1px white;
    background-image: url('/images/video-preview.png');
    background-size: cover;
}

.homeVideoLink {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.15s ease-in-out;
}

.homeVideoLink:hover {
    color: #cb3527;
}

@media (max-width: 600px) {
    .homeVideo {
        flex-direction: column;
    }
    .homeVideoPlayer {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
        border-left: none;
    }
}