.Machine {
    background-color: #222222;
}

.machineHero {
    min-height: calc(70vh - 72px);
    background-image: url('/images/bg-normandycrash.png');
    background-position: top left;
    background-size: cover;
    padding-top: 24px;
    padding-left: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    border-bottom: solid 1px black;
}

.machineBottom {
    background-image: url('/images/bg-longwalk.png');
    background-size: cover;
    background-position: center center;
    border-top: solid 1px black;
    min-height: 50vw;
}

@media (max-width: 600px) {
    .machineHero {
        background-image: url('/images/bg-normandycrash-vert.png');
    }
    .machineText.machineHeroText {
        padding-left: 0;
    }
}

.machineHeroText {
    max-width: 400px;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black;
}

.machineTitle {    
    font-size: 32pt;
    font-family: "me123";
}

.machineText {
    text-align: left;
    padding: 24px;
    font-size: 14pt;
    line-height: 1.2;
    flex-basis: 50%;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .machineText.machineSmall, .machineText.machineLinks {
        flex-basis: 100%;
    }
}

.machineBlock {
    /* padding: 24px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.machineImageContainer {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px;
    box-sizing: border-box;
}

.machineImageContainer.machineImageContainerWide {
    flex-basis: 50%;
    padding: 12px 24px;
}


.machineImageFrame {
    background-color: #444;
    border: solid 1px #bbb;
    border-radius: 8px;
}

.machineImage {
    border-radius: 8px 8px 0 0;
    width: 100%;
}
.machineImageText {
    padding: 4px;
    font-size: 10pt;
}

.machineLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.machineLink {
    text-decoration: none;
    color: #e68e2d;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black;
}

.machineLink:hover {
    color: #cb3527;
}


@media (max-width: 600px) {
    .machineText, .machineImageContainer.machineImageContainerWide {
        flex-basis: 100%;
    }
    .machineImageContainer {
        flex-basis: 50%;
    }
    .machineBottom {
        background-image: url('/images/bg-longwalk-vert.png');
    }
}