@font-face {
  font-family: me123;
  src: url('/fonts/me123.ttf');
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #ffffff;
  background-color: #000000;
}

.appContent {
  flex-grow: 1;
  overflow-y: scroll;
  height: calc(100vh - 72px);
}

.nowrap {
  white-space: nowrap;
}

.comingSoon {
  font-size: 32px;
  margin: 40px auto;
}