.Modes {
    background-color: #222222;
}

.modeHero {
    min-height: calc(100vh - 72px);
    background-image: url('/images/bg-galaxymap.png');
    background-position: center center;
    background-size: cover;
    padding-top: 24px;
    padding-right: 40%;
    display: flex;
    flex-direction: row-reverse;
}

@media (max-width: 600px) {
    .modeHero {
        background-image: url('/images/bg-galaxymap-vert.png');
        padding-right: 25%;
    }
}

@media (max-width: 400px) {
    .modeHero {
        padding-right: 0;
    }
}

.modeHeroContent {
    max-width: 480px;
    text-shadow: 1px 1px 1px black;
}

.modeHeroTitle {
    font-size: 32pt;
    font-family: "me123";
}

.modeHeroText {
    text-align: left;
    margin: 24px;
    font-size: 16pt;
    line-height: 1.2;
}

.modeImageContainer {
    text-align: center;
    padding: 16px 0 8px 0;
}
.modeImage {
    max-width: 100%;
}

.modeLink {
    font-family: "me123";
    display: block;
    text-align: center;
    margin: 32px 8px;
    text-decoration: none;
    color: #e68e2d;
}

.modeLink:hover {
    color: #cb3527;
}

.modeSection {
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {
    .modeSection {
        flex-direction: column;
    }
}

.modeSectionTitle {
    font-family: "me123";
    font-size: 24pt;
    margin-bottom: 12px;
    padding: 12px;
    background-color:#452a0d;
}

.modeSectionIntro {
    flex-basis: 50%;
    text-align: left;
    padding: 12px;
}

.modeSectionList {
    white-space: nowrap;
    text-align: left;
    padding: 12px;
    border-bottom: solid 1px #c28243;
    min-width: 240px;
}
.modeSectionListContainer {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.modeSectionListHeader {
    color: #c28243;
    font-family: "me123";
    border-bottom: solid 1px #c28243;
    margin-bottom: 12px;
    padding-bottom: 8px;
}
.modeSectionListTitle {
    font-weight: bold;
    line-height: 1.6;
}
.modeSectionListTitleSub {
    margin-top: -0.2em;
    line-height: 1.5;
    margin-left: 2em;
    font-style: italic;
}

/* MODE ENTRY */
.modeEntryContent {
    background-color: rgba(32,32,32,0.9);
    display: flex;
    flex-direction: row;
    padding: 24px;
    align-items: center;
}

.modeEntry:nth-child(even) .modeEntryContent {
    flex-direction: row-reverse;
}

.modeEntryTitle {
    font-family: "me123";
    font-size: 18pt;
}

.modeEntrySummary {
    font-style: italic;
}

.modeEntryText {
    text-align: justify;
    margin: 12px 0;
}

@media (max-width: 600px) {
    .modeEntryContent {
        flex-direction: column !important;
    }
}

.modeEntryStatus {
    text-align: left;
}

.modeEntryStatusTitle {
    font-family: "me123"
}
.modeEntryStatusText {
    margin-bottom: 12px;
}