.Tour {
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: white;
    color: black;
    background-image: url('/images/bg-endrun.png');
    background-position: center right;
    background-size: cover;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
}

.tourTitle {
    font-family: "me123";
    font-size: 32pt;
    text-shadow: none;
}

.tourContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 600px) {
    .Tour {
        background-image: url('/images/bg-endrun-vert.png');
    }
    .tourContainer {
        flex-direction: column-reverse;
    }
}

.tourDatesContainer {
    padding: 0 24px;
    text-align: center;
}

.cancelled {
    text-decoration: line-through;
}
.tourCancelled{
    color: #cb3527;
    text-shadow: none;
}

.tourYear {
    font-family: "me123";
    font-size: 24pt;
    color: #e68e2d;
    margin-top: 12px;
    margin-bottom: 8px;
    text-shadow: none;
}

.tourShow {
    font-size: 16pt;
    margin-top: 12px;
    white-space: nowrap;
}

.tourLink {
    font-size: 16pt;
    white-space: nowrap;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    text-decoration: none;
}

.tourLink:hover {
    color: #cb3527;
}

.tourLocation {
    font-size: 12pt;
}

.tourDates {
    font-size: 10pt;
    font-style: italic;
}

.tourTextContainer {
    padding: 12px;
    font-size: 16pt;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.tourTextContainer p {
    margin-top: 8px;
    margin-bottom: 8px;
}

@media (max-width: 600px) {
    .tourTextContainer {
        align-items: center;
        flex-direction:
    }
}